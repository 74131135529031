.grid-form {
    display: grid;
    grid-template-columns:  4fr 8fr 8fr 20fr;
    grid-gap: 1rem; /* Adjust the gap as needed */
    width: 300%;
  }
  
  /* Style for labels (optional) */
  .grid-form label {
    font-weight: bold;
  }
  
  /* Style for inputs (optional) */
  .grid-form input, .grid-form textarea {
    width: 100%;
    padding: 0.5rem;
  }
  